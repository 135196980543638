html {
  height: 100%;
}
body {
  margin: 0;
  padding: 0;
  font-family: 'Lato', sans-serif;
  background-color: #fff;
  min-height: 100%;
}
header {
  background-color: #ec3a49;
  color: white;
  padding: 5rem 0;
}
h1 {
  color: white;
  font-weight: 900;
  text-align: center;
  font-size: 2rem;
}

.row {
  margin-right: 0px;
  margin-left: 0px;
}
